<template>
  <div>
    <!--    <b-row class="mb-2">-->
    <!--      <b-col md="12">-->
    <!--        <export-button :filter="exportFilter"-->
    <!--                       url="/transaction/report-players/export"-->
    <!--                       :disabled="loadindData ||  playerSelectId === null"-->
    <!--                       file-title="Players_Report.xlsx"/>-->
    <!--      </b-col>-->
    <!--    </b-row>-->

    <b-row>
      <b-col md="4">
        <b-card class="dashboard-card text-center">
          <div class="card-d-header">
            <div class="card-icon">
              <feather-icon icon="UsersIcon" size="20" />
            </div>
            <span class="card-header-text text-primary">{{
              $t("total_players")
            }}</span>
          </div>
          <div class="card-value">
            <b-skeleton v-if="loadingStatsHeaders" width="20" height="20"></b-skeleton>
            <span v-else>{{ headLine.totalUsers }}</span>
          </div>
        </b-card>
      </b-col>

      <b-col md="4">
        <b-card class="dashboard-card text-center">
          <div class="card-d-header">
            <div class="card-icon">
              <feather-icon icon="DollarSignIcon" size="20" />
            </div>
            <span class="card-header-text text-primary">{{
              $t("total_balance")
            }}</span>
          </div>
          <div class="card-value">
            <b-skeleton v-if="loadingStatsHeaders" width="20" height="20"></b-skeleton>
            <span v-else>
              {{ headLine.totalBalance | currency({ symbol: "" }) }}</span
            >
          </div>
        </b-card>
      </b-col>

      <b-col md="4">
        <b-card class="dashboard-card text-center">
          <div class="card-d-header">
            <div class="card-icon">
              <feather-icon icon="UserIcon" size="20" />
            </div>
            <span class="card-header-text text-primary">{{
              $t("players_with_balance")
            }}</span>
          </div>
          <div class="card-value">
            <b-skeleton v-if="loadingStatsHeaders" width="10" height="40"></b-skeleton>
            <span v-else>{{ headLine.usersWithBalance }}</span>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col md="12">
        <b-card no-body class="p-1">
          <b-card-title class="mb-2">{{
            $t("player_report_new")
          }}</b-card-title>

          <div class="m-2`">
            <b-row>
              <b-col
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>{{ $t("labels.show") }}</label>
                <v-select
                  v-model="serverParams.limit"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  @option:selected="getData"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ $t("labels.entries") }}</label>
              </b-col>
            </b-row>
          </div>

          <b-row class="mt-1">
            <b-col md="3">
              <b-form-group
                label="Date from"
                label-for="datefrom"
                rules="required"
              >
                <template #label>{{ $t("labels.datefrom") }}</template>
                <flat-pickr
                  v-model="dateFromP"
                  class="form-control"
                  :config="{
                    altInput: true,
                    altFormat: 'd-m-Y H:i',
                    enableTime: true,
                    time_24hr: true,
                    wrap: true,
                    plugins: [
                      new yearDropdownPlugin({
                        yearStart: 100,
                        yearEnd: 0,
                      }),
                    ],
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Date To" label-for="dateto" rules="required">
                <template #label>{{ $t("labels.dateto") }}</template>
                <flat-pickr
                  v-model="dateToP"
                  class="form-control"
                  :config="{
                    altInput: true,
                    altFormat: 'd-m-Y H:i',
                    enableTime: true,
                    time_24hr: true,
                    wrap: true,
                    plugins: [
                      new yearDropdownPlugin({
                        yearStart: 100,
                        yearEnd: 0,
                      }),
                    ],
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Player Name" label-for="username">
                <template #label>{{ $t("labels.name_player") }}</template>
                <v-select
                  @option:selected="selectPlayer"
                  v-model="userSelected"
                  :options="userList"
                  @search="onSearch"
                  :filterable="false"
                  :clearable="true"
                  label="userName"
                  :placeholder="$t('buttons.search')"
                >
                  <template slot="no-options">
                    {{ $t("type_a_letter_to_start_the_search") }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.userName }}
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                :label="$t('labels.search_historic')"
                label-for="isHistorical"
              >
                <b-form-checkbox v-model="isHistorical" @change="getData" />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                  :label="$t('user_created')"
                  label-for="createdUsers"
              >
                <b-form-checkbox
                    v-model="createdUsers"
                    @change="getData"
                    switch
                />
              </b-form-group>
            </b-col>
            
          </b-row>
          <br />
          <b-overlay :show="loadindData || loadingTotal">
            <b-table
              hover
              small
              caption-top
              responsive
              show-empty
              :items="principal"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              @sort-changed="onSortChanged"
              foot-clone
              :empty-text="$t('message.no_records_found')"
            >
              <template #cell(totalBetsAmmount)="data">
                {{ data.item.totalBetsAmmount | currency({ symbol: "" }) }}
              </template>
              <template #cell(totalWinsAmmount)="data">
                {{ data.item.totalWinsAmmount | currency({ symbol: "" }) }}
              </template>
              <template #cell(netIncome)="data">
                {{ data.item.netIncome | currency({ symbol: "" }) }}
              </template>
              <template #cell(balance)="data">
                {{ data.item.balance | currency({ symbol: "" }) }}
              </template>

              <template #foot(_id)>
                {{ $t("total") }}
              </template>

              <template #foot(totalBetsAmmount)>
                <i>{{
                  dataTotal.totals.totalBetsAmmount | currency({ symbol: "" })
                }}</i>
              </template>

              <template #foot(totalWinsAmmount)>
                <i>{{
                  dataTotal.totals.totalWinsAmmount | currency({ symbol: "" })
                }}</i>
              </template>

              <template #foot(netIncome)>
                <i>{{
                    dataTotal.totals.totalNetIncome | currency({ symbol: "" })
                  }}</i>
              </template>

              <template #foot(balance)>
                <i>{{
                  dataTotal.totals.totalBalance | currency({ symbol: "" })
                }}</i>
              </template>

              <template #foot()>
                <i>-</i>
              </template>
            </b-table>

            <div class="mx-2 mb-2">
              <b-row>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="serverParams.page"
                    :total-rows="totalRows"
                    :per-page="serverParams.limit"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    @change="getData"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BButton,
  BAlert,
  BFormCheckbox,
  BCardText,
  BOverlay,
  BCardTitle,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTfoot,
  BTd,
  BTbody,
  BPagination,
} from "bootstrap-vue";
import { BTable } from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import "vue-tree-halower/dist/halower-tree.min.css";
import { VTree, VSelectTree } from "vue-tree-halower";
import userStoreModule from "../../../apps/user/userStoreModule";
// import clientStoreModule from "../../../pages/clients/clientStoreModule";
import reportAgentsStoreModule from "../reportAgentsStoreModule";
import whitelabelStoreModule from "../../../pages/whitelabel/whitelabelStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import ExportButton from "@/views/pages/export/ExportButton.vue";
import { axiosErrorHandle } from "@core/utils/errorHandler";
import { translatableText } from "@core/utils/utils";
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";
import axios from "@axios";
const APP_URL = process.env.VUE_APP_URL;

export default {
  components: {
    BPagination,
    ExportButton,
    BTable,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BCollapse,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BTableSimple,
    VSelectTree,
    BThead,
    vSelect,
    VTree,
    BTr,
    BTh,
    BForm,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    ToastificationContent,
    BAlert,
    BTfoot,
    BTd,
    BTbody,
    flatPickr,
  },
  data() {
    return {
      perPageOptions: [10, 25, 50, 100],
      totalPlayers: null,
      dateFromP: moment().format("YYYY-MM-DD:00:00:00"),
      dateToP: moment().format("YYYY-MM-DD:23:59:59"),
      minDate: moment("00:00", "h:mm").format("YYYY-MM-DD HH:MM"),
      maxDate: moment("23:59", "h:mm").format("YYYY-MM-DD HH:MM"),
      currency: "",
      currenciesOptions: [],
      initSelected: [],
      dataTable: [],
      rangeDate: "",
      idUserSelect: "",
      detailsDataChecked: true,
      loadindData: false,
      loadingTotal: false,
      loadingStatsHeaders: false,
      loadindDataBalancePlayer: false,
      userName: "",
      playerSelectId: null,
      dataTotal: {
        totals: {
          totalBalance: 0,
          totalRecharged: 0,
          totalRetired: 0,
          totalBetsAmmount: 0,
          totalNetIncome: 0,
          totalWinsAmmount: 0
        },
      },
      timeOut: null,
      userSelected: null,
      pages: [20, 40, 60],
      serverParams: {
        page: 1,
        limit: 50,
      },
      totalRows: 0,
      sortBy: "totalRecharged", // o 'totalRetired'
      sortDesc: false,
    };
  },
  watch: {
    dateFromP() {
      this.getData();
    },
    dateToP() {
      this.getData();
    },
    userSelected(value) {
      if (!value) {
        this.getData();
      }
    },
    whitelabelSelectNavbar() {
      this.getData();
    },
    currencySelectNavbar() {
      this.getData();
    },
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    const REPORT_AGENT_APP_STORE_MODULE_NAME = "app-report-agent";
    const WL_APP_STORE_MODULE_NAME = "app-whitelabel";

    onMounted(() => {
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
      if (!store.hasModule(REPORT_AGENT_APP_STORE_MODULE_NAME))
        store.registerModule(
          REPORT_AGENT_APP_STORE_MODULE_NAME,
          reportAgentsStoreModule
        );
      if (!store.hasModule(WL_APP_STORE_MODULE_NAME))
        store.registerModule(WL_APP_STORE_MODULE_NAME, whitelabelStoreModule);
    });

    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      if (store.hasModule(REPORT_AGENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(REPORT_AGENT_APP_STORE_MODULE_NAME);
      if (store.hasModule(WL_APP_STORE_MODULE_NAME))
        store.unregisterModule(WL_APP_STORE_MODULE_NAME);
    });

    const currencies = ref([]);
    const clientCurrencies = ref([]);
    const userList = ref([]);
    const previosNode = ref("");
    const previousNodeFlag = ref(false);
    const selection = ref([]);
    const dataGames = ref([]);
    const headLine = ref({
      totalBalance: 0,
      totalUsers: 0,
      usersWithBalance: 0,
    });
    const principal = ref([]);
    const isHistorical = ref(false);
    const createdUsers = ref(false);

    return {
      dataGames,
      principal,
      currencies,
      clientCurrencies,
      selection,
      previousNodeFlag,
      previosNode,
      userList,
      isHistorical,
      headLine,
      createdUsers
    };
  },
  computed: {
    currencySelectNavbar() {
      return this.$store.state.whitelabelCurrencyNabvar.currency;
    },
    whitelabelSelectNavbar() {
      return this.$store.state.whitelabelCurrencyNabvar.whitelabel;
    },
    fields() {
      return [
        { key: "createdAt", label: this.$t("createdDate") },
          
        { key: "_id", label: this.$t("labels.user_id") },
        { key: "userName", label: this.$t("labels.userName") },
        { key: "firstName", label: this.$t("labels.firstname") },
        { key: "lastName", label: this.$t("labels.lastname") },
        { key: "email", label: this.$t("labels.email") },
        { key: "inputDni", label: this.$t("labels.dni") },
          
        // { key: "email", label: this.$t("labels.email") },
        {
          key: "totalBetsAmmount",
          label: this.$t("plays"),
          sortable: true,
        },
        {
          key: "totalWinsAmmount",
          label: this.$t("rewards"),
          sortable: true,
        },
        {
          key: "netIncome",
          label: this.$t("labels.balance"),
          sortable: true,
        },
        { key: "balance", label: this.$t("balance") },
      ];
    },
    exportFilter() {
      return {
        datein: this.dateFromP,
        dateout: this.dateToP,
        id: this.userSelected?._id,
        currency: this.currencySelectNavbar,
        whitelabel: this.whitelabelSelectNavbar?._id,
        isHistorical: this.isHistorical,
      };
    },
  },
  methods: {
    yearDropdownPlugin,

    async getPlayerData() {
      this.principal = [];
      this.validateData();
      try {
        this.loadindData = true;
        const payload = {
          datein: this.dateFromP,
          dateout: this.dateToP,
          id: this.userSelected?._id || null,
          currency: this.currencySelectNavbar,
          whitelabel: this.whitelabelSelectNavbar._id,
          isHistorical: this.isHistorical,
          createdUsers: this.createdUsers,
          page: this.serverParams.page,
          pageSize: this.serverParams.limit,
          sortBy: this.sortBy,
          sortOrder: this.sortDesc ? "-1" : "1",
        };

        const { data } = await axios.get(
          `${APP_URL}/transaction/report-players-stats`,
          { params: payload }
        );

        this.principal = data.principal.data;
        // this.serverParams.page = data.principal.page;
        this.totalRows = data.principal.total;
        
        this.loadindData = false
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            text: await translatableText({ text: axiosErrorHandle(error) }),
            variant: "danger",
          },
        });
      } finally {
        this.loadindData = false;
      }
    },

    async getStatsHeaders() {
      this.loadingStatsHeaders = true

      this.headLine = {
        totalBalance: 0,
        totalUsers: 0,
        usersWithBalance: 0,
      };

      try {
        const payload = {
          currency: this.currencySelectNavbar,
          whitelabel: this.whitelabelSelectNavbar._id,
          // isHistorical: this.isHistorical,
        };

        const { data } = await axios.get(
          `${APP_URL}/transaction/report-players-stats-headers`,
          { params: payload }
        );

        this.headLine = data.headLine[0];

        this.loadingStatsHeaders = false

      } catch (error) {
        this.loadingStatsHeaders = false

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            text: await translatableText({ text: axiosErrorHandle(error) }),
            variant: "danger",
          },
        });
      } 
    },

    async getTotals() {
      this.principal = [];
      this.validateData();
      try {
        this.loadingTotal = true;
        const payload = {
          datein: this.dateFromP,
          dateout: this.dateToP,
          id: this.userSelected?._id || null,
          currency: this.currencySelectNavbar,
          whitelabel: this.whitelabelSelectNavbar._id,
          isHistorical: this.isHistorical,
        };

        const { data } = await axios.get(
          `${APP_URL}/transaction/report-players-stats-totals`,
          { params: payload }
        );

        this.dataTotal.totals = {
          totalBalance: data.totals.totalBalance,
          totalRecharged: data.totals.totalRecharged,
          totalRetired: data.totals.totalRetired,
          totalBetsAmmount: data.totals.totalBetsAmmount,
          totalNetIncome: data.totals.totalNetIncome,
          totalWinsAmmount: data.totals.totalWinsAmmount
        };

        this.loadingTotal = false;

      } catch (error) {
        this.loadingTotal = false;
        
        this.resetTotals();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            text: await translatableText({ text: axiosErrorHandle(error) }),
            variant: "danger",
          },
        });
      }
    },

    resetTotals() {
      this.dataTotal.totals = {
        totalBalance: 0,
        totalRecharged: 0,
        totalRetired: 0,
      };
    },

    getData() {
      this.getPlayerData();
      this.getStatsHeaders();
      this.getTotals();
    },

    validateData() {
      if (this.dateToP < this.dateFromP) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "XIcon",
            text: this.$t("review_dates"),
            variant: "danger",
          },
        });
      }
    },

    selectPlayer({ _id }) {
      this.getData();
    },

    onSearch(username, loading) {
      if (username.length) {
        loading(true);

        const whitelabelId =
          this.$store.state.whitelabelCurrencyNabvar.whitelabel._id;
        const queryParams = {
          username: username.trim(),
          whitelabel: whitelabelId,
        };

        if (this.timeOut) clearTimeout(this.timeOut);

        this.timeOut = setTimeout(() => {
          this.search(loading, queryParams, this);
        }, 500);
      }
    },

    search(loading, queryParams, vm) {
      loading(true);
      //console.log(this.userName.trim());.
      store
        .dispatch("app-user/fetchUsersByName", queryParams)
        .then((response) => {
          this.userList = response.data;
          loading(false);
        });
    },

    onSortChanged({ sortBy, sortDesc }) {
      this.sortBy = sortBy;
      this.sortDesc = sortDesc;
      this.getPlayerData(); // Llama a la función para obtener los datos
    },
  },

  created() {
    this.idUserSelect = JSON.parse(localStorage.getItem("userData"))._id;
    // this.getData();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.tree-block {
  float: left;
  width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 300px;
}

.selected {
  padding: 6px 8px;
  outline: none;
  border-radius: 6px;
  background-color: #efb810;
  border: 1px solid rgb(226, 225, 225);
  color: rgb(117, 117, 117);
}

.dashboard-card {
  //border: 1px solid #e5e5e5;
  //border-radius: 8px;
  //background: #ffffff;
  //box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  //padding: 1rem;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    transform: translateY(-3px);
    //box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  }

  .card-icon {
    color: #5bc0de;
  }

  .card-d-header {
    display: flex;
    padding: 0 !important;
    justify-items: flex-start !important;
    gap: 10px;
    align-items: center !important;
  }

  .card-header-text {
  }

  .card-value {
    font-size: 1.5rem;
    font-weight: bold;
    padding: 1rem;
  }
}
</style>
